/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body.backdrop-no-scroll {
  background: rgba(0,0,0,.3) !important;
}
.list-header{
  &__label{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    white-space: nowrap;
    color: var(--ion-color-primary, #3880ff);
  }
  &__btn{
    font-weight: 500;
    line-height: 12px;
    color: initial;
    opacity: .77;
    height: auto;
    padding: 0;
  }
}
.sp-header {
  box-shadow: 2px 2px 7px rgb(0 0 0 / 25%);
  &_transparent{
    box-shadow: none;
    ion-toolbar{

    }
  }
  &_white{
    ion-toolbar{
      //padding: 8px 16px !important;
    }
  }
  &_primary{
    ion-toolbar{
      --background: var(--ion-color-primary, #3880ff);
      //padding: 8px 16px !important;
      ion-title{
        color: #ffffff;
      }
      ion-back-button{
        --color: #ffffff;
      }
    }
  }
}
.sp-btn{
  --border-radius: 10px;
}
.ion-100{
  width: 100%;
}
.ml-10{
  margin-left: 10px;
}
.mr-10{
  margin-right: 10px;
}
.btn-50{
  width: calc(50% - 10px);
}
.mt-minus-10{
  margin-top: -10px;
}
.search-wrapper{
  padding: 8px;
}
.action-sheet-button {
  text-transform: capitalize;
  &.hide{
    display: none;
  }
  &.hot{
    color: #ff8100;
  }
}
ion{
  &-card{
    box-shadow: 2px 2px 12px rgb(0 0 0 / 12%);
    &-header {
      border-bottom: solid 1px rgba(0,0,0,.1);
      // background: rgb(30 153 214);
      // padding: 10px 15px 5px;
    }
    &-subtitle{
      //color: #fff;
    }
    &-content{
      ion-item{
        --background: transparent;
      }
    }
  }
}

.ion-no-padding-bottom{
  padding-bottom: 0 !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal>.swiper-pagination-bullets {
  width: 100px;
  left: calc(50% - 50px);
}
ion-list{
  background: transparent !important;
}
ion-item{
  --background: transparent;
}
ion-intl-tel-input{
  letter-spacing: 2px;
}


.sc-ion-alert-md-h {
  --max-width: 100% !important;
}
.alert-wrapper {
  width: 90% !important;
}

ion-modal.custom-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

